import React, { Component } from "react";
// import { Link } from "react-router-dom";
// import BannerImage from "../assets/qr.jpg";
// import Logo from "../assets/th.jpg";
// import Logo2 from "../assets/logo.svg";
// import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import "../styles/Home.css";
import Marquee from 'react-fast-marquee';
import {HashLink as Link} from 'react-router-hash-link';
import img1 from '../assets/pizza.jpeg';
import Logo from '../assets/pizzaLogo.png';
import img3 from '../assets/map.png';
import img4 from '../assets/moon.png';
import img5 from '../assets/sundial.jpg';

function Home() {

  
  return (

    

    <div className="home" id="home" 
    style={{ backgroundImage: `url(${img1})`
              // transform: [{rotate: '90deg' }]
  }}
    >
      {/* <img src={Logo} className="App-logo" alt="logo" /> */}

      <div className="headerContainer">
        {/* <div style={{ backgroundImage: `url(${Logo})`, width:"150px", height:"150px"}}></div> */}
        
        <p> YOUR TAGLINE: Delicious Pizzas And Electricifing Games</p>
        {/* <p> Eye Candy homePage that showcases what you're good at.  </p> */}
        {/* <p>    With quick link button to what people want most.</p> */}
          <Link smooth to="#contact">
          <button>
              {/* <CurrencyBitcoinIcon fontSize="large" /> */}
                Menu
          </button>
          </Link>
      </div>

        <div>

      </div>

    </div>
  );
}


export default Home;
