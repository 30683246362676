import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import ImageSlider from "./components/ImageCarousel";
import Home from "./pages/Home";
import Menu from "./pages/Menu";
import Menu2 from "./pages/Menu2";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Banner from "./components/Banner";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Podcast from "./pages/Podcast";
import Navbar2 from "./components/Navbar2";
import Community from "./pages/Community";
import MultiplePizzas from "./assets/restuarantbackground.jpg";

function App() {

  // if (window.location.href != "https://www.norcalhost.com/") {
  //   // console.log('works great!!');
  //   window.location = "https://www.norcalhost.com";
  // }

  return (
    <div className="App"
    style={{ backgroundImage: `url(${MultiplePizzas})` }} 
    >
      <Router>
        <Navbar2 />
        {/* <Banner /> */}
        <Home />
        <p style={{background:'black'}}>-----prototype 1: change homepage image to fit style of restuarant</p>
        {/* <Community /> */}
        {/* //////// add gallery */}
        <p style={{background:'black'}}>-----prototype 1: deciding to keep black background or changing to a soft wallpaper</p>
        {/* <Menu /> */}
        <Menu2 />
        {/* <p style={{background:'black'}}>-----prototype 1: deciding if events/music section is needed, then choosing an updating calendar or static weekly events  </p>
        <Podcast /> */}
        <p style={{background:'black'}}>-----prototype 1: about us page with more images and smaller split descriptions more art images </p>
        <About />
        <p style={{background:'black'}}>-----prototype 1: add image of outside of business</p>
        <Contact />
        {/* <Switch> */}
          {/* <Route path="/" exact component={Home} /> */}
          {/* <Route path="/menu" exact component={Menu} /> */}
          {/* <Route path="/about" exact component={About} /> */}
          {/* <Route path="/contact" exact component={Contact} /> */}
        {/* </Switch> */}
        <Footer />
      </Router>
    </div>
  );
}

export default App;
