import Pepperoni from "../assets/catchitquick.jpg";
import Margherita from "../assets/rpc.jpg";
import PedroTechSpecial from "../assets/cdc.jpg";
import Vegan from "../assets/vegan.jpg";
import Pineapple from "../assets/pineapple.jpg";
import Expensive from "../assets/expensive.jpg";

export const MenuList = [
  // {
  //   name: "Ecommerce for Merch",
  //   image: Pepperoni,
  //   linkTo: "https://catchitquickjuggling.com/",
  //   price: 15.99,
  //   description: "Item description -Jet black background to focus on store items and make them pop."  
  // },
  // {
  //   name: "Merch Title 2",
  //   image: Margherita,
  //   linkTo: "https://www.reddingcooppreschool.org/",
  //   price: 11.99,
  //   description: "Each Item links to your individual store items that you the owner have sole password protected control of with easy user interface and sales analytics "  
  // },
  // {
  //   name: "Ecommerce",
  //   image: PedroTechSpecial,
  //   linkTo: "https://cellardoorcider.com/index.html",
  //   price: 256.53,
  //   description: "For some businesses you may want to limit this store section to a smaller button or grouped with donations button in Contacts section.  This focuses on your mission."  
  // },
  {
    name: "Vegan Pizza",
    image: Vegan,
    price: 17.99,
    description: " laudantium molestias eos sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam recusandae alias error harum maxime adipisci amet la."  
  },
  {
    name: "Pineapple Pizza",
    image: Pineapple,
    price: 4.99,
    description: "Fruit on Pizza?...."  
  },
  {
    name: "Very Expensive Pizza",
    image: Expensive,
    price: 1997.99,
    description: "Kind of expensive, but you only live once.  YOLO. "  
  },
];
