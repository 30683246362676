import React, { useState, useEffect } from "react";
import Logo from "../assets/pizzaLogo.png";
import PizzaLeft from "../assets/gradient.jpeg";
// import { Link } from "react-router-dom";
// import Logo2 from "../assets/bitcoin_logo_2222.svg";
import {HashLink as Link} from 'react-router-hash-link';
import ReorderIcon from "@material-ui/icons/Reorder";
import CloseIcon from '@mui/icons-material/Close';
import ConfettiExplosion from 'react-confetti-explosion';
import "../styles/Navbar2.css";

import Marquee from 'react-fast-marquee';
import sp from "../assets/spaceInvader.png";

function Navbar2() {

  const [show, setShow] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  const [checked, setChecked] = useState(false);

  const handleCheck =() => {
    setChecked(!checked)
   }

   ////// confetti
   const [isExploding, setIsExploding] = React.useState(false);
   const [style, setstyle] = React.useState("but");
   const handleClick = () => {
     setIsExploding('true');
     setstyle("but2");
   }
  

const controlNavbar = () => {
  if (typeof window !== 'undefined') { 
    if (window.scrollY > lastScrollY) { // if scroll down hide the navbar
      setShow(true); 
    } else { // if scroll up show the navbar
      setShow(false);  
    }

    // remember current page location to use in the next move
    setLastScrollY(window.scrollY); 
  }
};

useEffect(() => {
  if (typeof window !== 'undefined') {
    window.addEventListener('scroll', controlNavbar);
    // cleanup function
    return () => {
      window.removeEventListener('scroll', controlNavbar);
    };
  }
}, [lastScrollY]);




  return (
    <div >
    <div >
    <nav >
      <div className={`navbar2 active2 ${show && 'hidden2'}`}>
        <div className="container nav-container">
          <img className="logo" src={Logo} />
            {/* <h3 style={{color: '#7A2002'}}>LOGO HERE</h3> */}
          {/* </div> */}
            <input className="checkbox" type="checkbox" 
            // name="" 
            // id="checkclick" 
            checked={checked} 
            onClick={handleCheck}
            />

<div className="theme">
              {/* <a href="https://oceanhero.today/search"> */}
              {/* <div> */}
                  {/* <h1>hey</h1> */}
                  <Marquee
                          gradient="false" 
                          gradientColor={[0,0,0]}
                  >
                    <div className={style} onClick={handleClick}>
                      {isExploding && <ConfettiExplosion 
                       //  style={{    
                         // }}
                         force={0.4}
                         duration={2200}
                         particleCount={30}
                         width={400}
                         />
                      
                      }
                    <img 
                    src={sp}/>

                    </div>
                    <img src={sp} 
                    // className='but' onClick={handleClick}
                    />
                    <img src={sp} 
                    // onClick={() => setIsExploding('true')}
                    />
                    <img src={sp}/>

                  </Marquee>
                {/* <p>Ocean Theme</p> */}
                {/* <SailingIcon></SailingIcon> */}
              </div>

            <div className="hamburger-lines" onClick={handleCheck}>
              <span className="line line1"></span>
              <span className="line line2"></span>
              <span className="line line3"></span>
            </div>  
          <div className="menu-items">
            <li onClick={handleCheck}><Link smooth to="#home">Home</Link></li>
            <li onClick={handleCheck}><Link smooth to="#podcast">Music</Link></li>
            <li onClick={handleCheck}><Link smooth to="#menu">Menu</Link></li>
            <li onClick={handleCheck}><Link smooth to="#contact">Location</Link></li>
          </div>
        </div>
      </div>
    </nav>
  </div>

  </div>
  );
}

export default Navbar2;