import React, { Component } from "react";
import { Link } from "react-router-dom";

function MenuItem2({ image, name, description,linkTo, price }) {
  return (
    <div className="menuItem2">
      
      {/* <a href={linkTo}> */}
        {/* <div style={{ backgroundImage: `url(${image})` }}> </div> */}
        <div className="mi2Left">
        <h2> {name} </h2>
        <p> {description} </p>
        </div>

      {/* </a>  */}
      <p > ${price} </p>
    </div>
  );
}

export default MenuItem2;
